import { collection, doc } from 'firebase/firestore';
import type { Brand } from '@tn/shared';

export const useBrand = () => {
  const db = useFirestore();
  const { brandUser } = useCurrentUserDetails();

  const brandId = brandUser?.brand.id;

  if (!brandId) {
    console.error('Brand id is undefined');
    return;
  }

  const brandRef = doc(collection(db, 'brands'), brandId);
  const brand = useDocument<Brand>(brandRef);

  return { brand: brand, loading: brand.pending, error: brand.error.value };
};
